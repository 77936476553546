// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, addDoc, collection } from 'firebase/firestore'
// import { getStorage, ref, uploadBytes } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAN95ltCtt9g4b6Tb1fl2nsg8hOO5QPVB0',
  authDomain: 'substanceradiance.firebaseapp.com',
  projectId: 'substanceradiance',
  storageBucket: 'substanceradiance.appspot.com',
  messagingSenderId: '139206465690',
  appId: '1:139206465690:web:cb4f20294059dad8886723',
  measurementId: 'G-TEZSKJS251'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
// const storage = getStorage()

// Create a new cart
const createContact = async function createContact(contact) {
  const res = await addDoc(collection(db, 'contacts-labs'), contact)
  return res.id
}

export default createContact
