<template>
  <div class="page-wrapper">
    <header class="navbar">
      <div class="logo-wrap">
        <img src="@/assets/img/logo.svg" alt="Substance Radiance logo" class="logo" />
      </div>
      <div class="right">
        <!-- <a v-scroll-to:-95 href="#programmation" class="link"
          ><p class="caps-bold">HORAIRE<span> DE LA JOURNÉE</span></p></a
        > -->
        <a href="/" class="cta"><p class="caps-bold">Retour</p></a>
      </div>
    </header>

    <section class="hero hero-success">
      <div class="content">
        <h2 class="title big" data-inview="fadeInUp" data-delay="200">Merci</h2>
        <h1 class="title" data-inview="fadeInUp" data-delay="300">
          Merci d'avoir confirmé <br />
          votre présence.
        </h1>
      </div>
    </section>

    <section class="footer">
      <img src="@/assets/img/logo-blanc.svg" alt="Logo Substance" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Une décennie de succès et d’expertises à célébrer'
      },
      meta: [
        {
          name: 'description',
          content: 'Le 13 novembre prochain, dès 10h, on vous invite à prendre un moment pour découvrir les différents visages de l’agence.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Une décennie de succès et d’expertises à célébrer' },
        {
          name: 'twitter:title',
          content: 'Le 13 novembre prochain, dès 10h, on vous invite à prendre un moment pour découvrir les différents visages de l’agence.'
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Le 13 novembre prochain, dès 10h, on vous invite à prendre un moment pour découvrir les différents visages de l’agence.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Une décennie de succès et d’expertises à célébrer' },
        { property: 'og:site_name', content: 'Une décennie de succès et d’expertises à célébrer' },
        {
          property: 'og:description',
          content: 'Le 13 novembre prochain, dès 10h, on vous invite à prendre un moment pour découvrir les différents visages de l’agence.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://substance-10-ans.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://substance-10-ans.netlify.app/img/og.jpg'
        }
      ]
    }
  },

  data() {
    return {
      form: false,
      formData: {
        nom: '',
        entreprise: '',
        invites: ''
      },
      isSending: false
    }
  },

  methods: {
    openform() {
      this.form = true
    },
    closeform() {
      this.form = false
    }
  },

  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
