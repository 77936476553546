<template>
  <div class="page-wrapper">
    <header class="navbar">
      <div class="logo-wrap">
        <img class="logo" src="@/assets/img/substance-logo-2023.png" alt="Substance" />
      </div>
      <div class="right">
        <a href="#programmation" v-scroll-to:-95 class="link"><p class="caps-bold">HORAIRE<span> DE LA JOURNÉE</span></p></a>
        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">PARTICIPER</p></a>
      </div>
    </header>

    <section class="hero">
      <img src="@/assets/img/hero.svg" alt="Substance Lab 2024">
      <!-- <a href="#" class="cta outline float-cta">Rendez-vous annuel substance</a> -->
    </section>

    <section class="img-bloc stretch">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title big">Le LAB SUBSTANCE <br> Votre rendez-vous périodique</h2>
        <h3 class="title small">Solidifiez vos connaissances <br> Participez à l'expérience</h3>
        <p class="text">
          Après le succès des formations pour notre 10e anniversaire de l'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l'industrie en 2025.
          <br><br>
          Un événement en effervescence pour consolider vos connaissances, tester la chimie avec de nouvelles connexions et prendre part à la conversation!
          <br><br>
          Soyez des nôtres le 24 janvier. 
          <br><br>
          Envie d’être en avance?
        </p>
        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">Confirmer ma présence</p></a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="infos-card">
          <div class="info">
            <p class="title">QUAND</p>
            <p class="info-text">24 janvier <br> 10H</p>
          </div>
          <div class="info">
            <p class="title">Où</p>
            <p class="info-text">messorem <small>2233.rue Pitt.montréal</small></p>
          </div>
        </div>
      </div>
    </section>

    <section id="programmation" class="programmation">
      <div class="title-wrap">
        <h2 class="title big" data-inview="fadeInUp" data-delay="200">Le Lab Substance 2024</h2>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">10h</p>
        </div>
        <div class="bloc no-click bleu">
          <div class="top">
            <h3 class="title small">Connexion formule réseautage</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <!-- <div class="arrow">
            <img src="@/assets/img/arrow-right.svg" alt="arrow">
          </div> -->
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">10h30</p>
        </div>
        <div class="bloc-wrap">
          <div class="bloc" @click.prevent="openconference('event1')">
            <div class="top">
              <h3 class="title small">Influence et performance : L'importance du ROI en temps réel</h3>
            </div>
            <div class="info">
              <p class="text salle">L'Atelier</p>
              <div class="speaker">
                <img src="@/assets/img/marc-andre.webp" alt="Marylyna Larrivée" />
                <img src="@/assets/img/leila.webp" alt="Marc-André Dufresne" class="ml" />
                <p class="text name"><span>/</span>  Marc-Andrée Dufresne <br> Leila Vitro</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
          <p class="text or">ou</p>
          <div class="bloc" @click.prevent="openconference('event2')">
            <div class="top">
              <h3 class="title small">Données intégrées : Pour des campagnes qui atteignent des sommets</h3>
            </div>
            <div class="info">
               <p class="text salle">Le Labo</p>
              <div class="speaker">
                <img src="@/assets/img/audrey.webp" alt="Marylyna Larrivée" />
                <img src="@/assets/img/elise.webp" alt="Marc-André Dufresne" class="ml" />
                <p class="text name"><span>/</span> Audrey Dodier <br> Elise El-Nawar</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">11h</p>
        </div>
        <div class="bloc-wrap">
          <div class="bloc" @click.prevent="openconference('event3')">
            <div class="top">
              <h3 class="title small">Médias d'ici: Générer de la performance durable grâce aux placements médias nationaux</h3>
             
            </div>
            <div class="info">
              <p class="text salle">L'Atelier</p>
              <div class="speaker">
                <img src="@/assets/img/philippe.webp" alt="Marc-André Dufresne" />
                <img src="@/assets/img/marie-jade.webp" alt="" class="ml" />
                <p class="text name"><span>/</span> Philippe Pétrin <br> Marie-Jade Filion</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
          <p class="text or">ou</p>
          <div class="bloc" @click.prevent="openconference('event4')">
            <div class="top">
              <h3 class="title small">Gestion des interactions : L'importance du dialogue numérique entre la marque et son client</h3>
              
            </div>
            <div class="info">
              <p class="text salle">Le Labo</p>
              <div class="speaker">
                <img src="@/assets/img/marc-andre.webp" alt="">
                <img src="@/assets/img/genevieve.webp" alt="" class="ml">
                <img src="@/assets/img/flavie.webp" alt="" class="ml">
                <p class="text name"><span>/</span> Marc-Andrée Dufresne <br> Geneviève Harel <br> Flavie Guévin</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">11h30</p>
        </div>
        <div class="bloc-wrap">
          <div class="bloc" @click.prevent="openconference('event5')">
            <div class="top">
              <h3 class="title small">Réduction budgétaire: 5 façons d'en avoir plus pour ses $ marketing</h3>
            </div>
            <div class="info">
              <p class="text salle">L'Atelier</p>
              <div class="speaker">
                <img src="@/assets/img/roxane.webp" alt="" />
                <p class="text name"><span>/</span> Roxane Noiseux</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
          <p class="text or">ou</p>
          <div class="bloc" @click.prevent="openconference('event6')">
            <div class="top">
              <h3 class="title small">Mix Media Modeling</h3>
            </div>
            <div class="info">
              <p class="text salle">Le Labo</p>
              <div class="speaker">
                <img src="@/assets/img/eulerian.webp" alt="Eulerian" />
                <p class="text name"><span>/</span> Eulerian</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
        </div>
      </div>


     <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">12h</p>
        </div>
        <div class="bloc no-click bleu">
          <div class="top">
            <h3 class="title small">Lunch & Connexion Réseautage</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <!-- <div class="arrow">
            <img src="@/assets/img/arrow-right.svg" alt="arrow">
          </div> -->
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">12h30</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event7')">
          <div class="top">
            <h3 class="title small">Décortiquer Barbie - La campagne à succès de 2023</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/audrey.webp" alt="" />
              <img src="@/assets/img/maxime.webp" alt="" class="ml">
              <img src="@/assets/img/marc-andre.webp" alt="" class="ml">
              <img src="@/assets/img/roxane.webp" alt="" class="ml">
              <p class="text name"><span>/</span>  Audrey Dodier <br> Maxime Soucy <br> Marc-André Dufresne <br> Roxane Noiseux</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">13h15</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event8')">
          <div class="top">
            <h3 class="title small">Think - Do - Say</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/ron.webp" alt="" />
              <p class="text name"><span>/</span> Ron Tite</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">14h</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event9')">
          <div class="top">
            <h3 class="title small">Pinterest : Full Funnel marketing</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/pinterest.webp" alt="" />
              <p class="text name"><span>/</span> Pinterest</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">14h45</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event10')">
          <div class="top">
            <h3 class="title small">Leadership et marketing : 2 métiers qui se rencontrent</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/catherine.webp" alt="" />
              <p class="text name"><span>/</span> Catherine Patry </p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">15h30</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event11')">
          <div class="top">
            <h3 class="title small">Connexion formule réseautage</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <!-- <img src="@/assets/img/tiktok.png" alt="Tik Tok logo" /> -->
              <!-- <p class="text name"><span>/</span> par Tik Tok</p> -->
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>
    </section>

    <section class="img-bloc yellow">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">NE MANQUEZ PAS ÇA !</h2>
        <p class="text darker">
          Le 24 janvier prochain, on vous attend dès 10h à la microbrasserie Messorem.
        </p>
        <h3 class="title small">MESSOREM</h3>
        <p class="text darker">2233 Rue Pitt, Montréal, QC H4E 4H2</p>
        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">Confirmer ma présence</p></a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <a
          href="https://www.google.com/maps?q=messorem+bracitorium&gs_lcrp=EgZjaHJvbWUqDQgBEC4YrwEYxwEYgAQyBggAEEUYOTINCAEQLhivARjHARiABDIHCAIQABiABDIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABNIBCDM2ODJqMGoxqAIAsAIA&um=1&ie=UTF-8"
          target="_blank"
          ><img src="@/assets/img/messorem.svg" alt="Google map"
        /></a>
      </div>
    </section>

    <section class="footer">
      <img class="logo" src="@/assets/img/substance-logo-2023.png" alt="Logo Substance">
    </section>

    <!--FORM-->
    <section class="panel-wrap" :class="{ active: form }">
      <div class="panel" v-if="!isSuccess">
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeform" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>

        <p class="title small">Confirmer ma présence</p>

        <form action="/?success=1" name="soumissions" method="post" enctype="multipart/form-data" @submit="sendForm">
          <input v-model="formData.nom" type="text" placeholder="Prénom et nom" required />
          <input v-model="formData.entreprise" type="text" placeholder="Entreprise" required />
          <input v-model="formData.courriel" type="mail" placeholder="Courriel" required>

          <div class="wrap">
            <p class="title hour">10h</p>
            <label class="choice" @click.prevent.stop="addFormation('10h : Connexion formule réseautage')" >
              <div class="prevent"></div>
              Connexion formule réseautage
              <input type="checkbox" value="Connexion formule réseautage" :checked="formData.formations.includes('10h : Connexion formule réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">10h30</p>

            <label class="choice half" @click.prevent.stop="addFormation('10h30 : Influence et performance : L\'importance du ROI en temps réel', '10h30')" >
              <div class="prevent"></div>
              Influence et performance : L'importance du ROI en temps réel
              <input type="radio" value="Influence et performance : L'importance du ROI en temps réel" :checked="formData.formations.includes('10h30 : Influence et performance : L\'importance du ROI en temps réel')" />
              <div class="radio-faker"></div>
            </label>
            <p class="text">ou</p>
            <label class="choice half" @click.prevent.stop="addFormation('10h30 : Données intégrées : Pour des campagnes qui atteignent des sommets', '10h30')" >
              <div class="prevent"></div>
              Données intégrées : Pour des campagnes qui atteignent des sommets
              <input type="radio" value="Données intégrées : Pour des campagnes qui atteignent des sommets" :checked="formData.formations.includes('10h30 : Données intégrées : Pour des campagnes qui atteignent des sommets')" />
              <div class="radio-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">11h</p>

            <label class="choice half" @click.prevent.stop="addFormation('11h : Médias d\'ici: Générer de la performance durable grâce aux placements médias nationaux', '11h')" >
              <div class="prevent"></div>
              Médias d'ici: Générer de la performance durable grâce aux placements médias nationaux
              <input type="radio" value="Médias d'ici: Générer de la performance durable grâce aux placements médias nationaux" :checked="formData.formations.includes('11h : Médias d\'ici: Générer de la performance durable grâce aux placements médias nationaux')" />
              <div class="radio-faker"></div>
            </label>
            <p class="text">ou</p>
            <label class="choice half" @click.prevent.stop="addFormation('11h :Gestion des interactions : L\'importance du dialogue numérique entre la marque et son client', '11h')" >
              <div class="prevent"></div>
             Gestion des interactions : L'importance du dialogue numérique entre la marque et son client
              <input type="radio" value="Gestion des interactions : L'importance du dialogue numérique entre la marque et son client" :checked="formData.formations.includes('11h :Gestion des interactions : L\'importance du dialogue numérique entre la marque et son client')" />
              <div class="radio-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">11h30</p>

            <label class="choice half" @click.prevent.stop="addFormation('11h30 : Réduction budgétaire: 5 façons d\'en avoir plus pour ses $ marketing', '11h30')" >
              <div class="prevent"></div>
              Réduction budgétaire: 5 façons d'en avoir plus pour ses $ marketing
              <input type="radio" value="Réduction budgétaire: 5 façons d'en avoir plus pour ses $ marketing" :checked="formData.formations.includes('11h30 : Réduction budgétaire: 5 façons d\'en avoir plus pour ses $ marketing')" />
              <div class="radio-faker"></div>
            </label>
            <p class="text">ou</p>
            <label class="choice half" @click.prevent.stop="addFormation('11h30 :Mix Media Modeling', '11h30')" >
              <div class="prevent"></div>
             Mix Media Modeling
              <input type="radio" value="Mix Media Modeling" :checked="formData.formations.includes('11h30 :Mix Media Modeling')" />
              <div class="radio-faker"></div>
            </label>


          </div>

          

          <div class="wrap">
            <p class="title hour">12h</p>
            <label class="choice" @click.prevent.stop="addFormation('12h : Lunch & Connexion Réseautage')" >
              <div class="prevent"></div>
              Lunch & Connexion Réseautage
              <input type="checkbox" value="Lunch & Connexion Réseautage" :checked="formData.formations.includes('12h : Lunch & Connexion Réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">12h30</p>
            <label class="choice" @click.prevent.stop="addFormation('12h30 : Décortiquer Barbie - La campagne à succès de 2023')" >
              <div class="prevent"></div>
              Décortiquer Barbie - La campagne à succès de 2023
              <input type="checkbox" value="Décortiquer Barbie - La campagne à succès de 2023" :checked="formData.formations.includes('12h30 : Décortiquer Barbie - La campagne à succès de 2023')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>
          <div class="wrap">
            <p class="title hour">13h15</p>
            <label class="choice" @click.prevent.stop="addFormation('13h15 : Think - Do - Say')" >
              <div class="prevent"></div>
              Think - Do - Say
              <input type="checkbox" value="Think - Do - Say" :checked="formData.formations.includes('13h15 : Think - Do - Say')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>
          <div class="wrap">
            <p class="title hour">14h</p>
            <label class="choice" @click.prevent.stop="addFormation('14h : Pinterest : Full Funnel marketing')" >
              <div class="prevent"></div>
              Pinterest : Full Funnel marketing
              <input type="checkbox" value="Pinterest : Full Funnel marketing" :checked="formData.formations.includes('14h : Pinterest : Full Funnel marketing')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>
          <div class="wrap">
            <p class="title hour">14h45</p>
            <label class="choice" @click.prevent.stop="addFormation('14h45 : Leadership et marketing : 2 métiers qui se rencontrent')" >
              <div class="prevent"></div>
              Leadership et marketing : 2 métiers qui se rencontrent
              <input type="checkbox" value="Leadership et marketing : 2 métiers qui se rencontrent" :checked="formData.formations.includes('14h45 : Leadership et marketing : 2 métiers qui se rencontrent')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>
          <div class="wrap">
            <p class="title hour">15h30</p>
            <label class="choice" @click.prevent.stop="addFormation('15h30 : Connexion formule réseautage')" >
              <div class="prevent"></div>
              Connexion formule réseautage
              <input type="checkbox" value="Connexion formule réseautage" :checked="formData.formations.includes('15h30 : Connexion formule réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <input type="submit" value="Envoyer ma participation">
        </form>
      </div>
      <div class="panel success-screen" v-else>
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeform" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>
        
        <p class="title small">Merci de votre <br> inscription!</p>
        <p class="text">
        <br>
        Rendez-vous le 24 janvier prochain à la brasserie Messorem </p>
      </div>
      <div class="overlay" @click.prevent="closeform"></div>
    </section>

    <!--Conférence Panel-->
    <section class="panel-wrap" :class="{ active: conference }">
      <div class="panel">
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeconference" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>

        <div class="event" :class="{ show: show === 'event1' }">
          <div class="top">
            <p class="hour title">10h30</p>
            <h3 class="title small">Influence et performance : L'importance du ROI en temps réel</h3>
           <p class="text salle">L'Atelier</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Prenez connaissance de l'importance stratégique du marketing d'influence;</li>
              <li class="text">Apprivoisez l'essentiel des outils et des partenariats cruciaux pour réussir en marketing d'influence.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marc-andre.webp" alt="">
            <p class="text name"><span>/</span> Marc-Andrée Dufresne </p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/leila.webp" alt="">
            <p class="text name"><span>/</span> Leila Vitro</p>
          </div>

          

          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event2' }">
          <div class="top">
            <p class="hour title">10h30</p>
            <h3 class="title small">Données intégrées : Pour des campagnes qui atteignent des sommets</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Découvrez les outils à utiliser pour avoir un portrait fidèle de l'impact des placements médias;</li>
              <li class="text">Atteignez une meilleure rentabilité de l’investissement et retour sur investissement;</li>
              <li class="text">Identifiez les solutions aux préoccupations liées aux changements numériques.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/audrey.webp" alt="">
            <p class="text name"><span>/</span> Audrey Dodier</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/elise.webp" alt="">
            <p class="text name"><span>/</span> Elise El-Nawar</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event3' }">
          <div class="top">
            <p class="hour title">11h</p>
            <h3 class="title small">Médias d'ici: Générer de la performance durable grâce aux placements médias nationaux</h3>
           <p class="text salle">L'Atelier</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Comprenez l'intégration des médias d’ici dans la planification média;</li>
              <li class="text">Explorez la manière dont les médias d’ici peuvent rivaliser avec les GAMA;</li>
              <li class="text">Discutez des placements durables.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/philippe.webp" alt="">
            <p class="text name"><span>/</span> Philippe Pétrin</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marie-jade.webp" alt="">
            <p class="text name"><span>/</span> Marie-Jade Filion</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event4' }">
          <div class="top">
            <p class="hour title">11h</p>
            <h3 class="title small">Gestion des interactions : L'importance du dialogue numérique entre la marque et son client</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Démystifiez l'importance stratégique de la gestion de communauté;</li>
              <li class="text">Maîtrisez l'essentiel des compétences nécessaires pour réussir sur les médias sociaux;</li>
              <li class="text">Apprenez les partenaires et les outils les plus performants.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marc-andre.webp" alt="">
            <p class="text name"><span>/</span> Marc-Andrée Dufresne</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/genevieve.webp" alt="">
            <p class="text name"><span>/</span> Geneviève Harel</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/flavie.webp" alt="">
            <p class="text name"><span>/</span> Flavie Guévin</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event5' }">
          <div class="top">
            <p class="hour title">11h30</p>
            <h3 class="title small">Réduction budgétaire: 5 façons d'en avoir plus pour ses $ marketing</h3>
            <p class="text salle">L'Atelier</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Discernez les canaux qui offrent de la pérennité à l'organisation dans le contexte économique actuel;</li>
              <li class="text">Voyez comment développer une connexion humaine avec sa cible et le résultat sur ses comportements;</li>
              <li class="text">Découvrez les grands impacts de la transformation d'un canal propriétaire.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/roxane.webp" alt="">
            <p class="text name"><span>/</span> Roxane Noiseux</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event6' }">
          <div class="top">
            <p class="hour title">11h30</p>
            <h3 class="title small">Mix Media Modeling</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/eulerian.webp" alt="Eulerian">
            <p class="text name"><span>/</span> Eulerian</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event7' }">
          <div class="top">
            <p class="hour title">12h30</p>
            <h3 class="title small">Décortiquer Barbie - La campagne à succès de 2023</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <h4 class="text small"><strong>Table ronde sur les bons coups de la campagne de promotion sur le film Barbie.</strong> </h4>
            <ul>
              <li class="text">Les éléments aptes à être réutilisés et adaptés;</li>
              <li class="text">La segmentation du marché et le ciblage;</li>
              <li class="text">L'impact de la créativité média;</li>
              <li class="text">L'activation du social et de l'influence;</li>
              <li class="text">La stratégie Bread Crumb.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/audrey.webp" alt="">
            <p class="text name"><span>/</span> Audrey Dodier</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/maxime.webp" alt="">
            <p class="text name"><span>/</span> Maxime Soucy</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marc-andre.webp" alt="">
            <p class="text name"><span>/</span> Marc-André Dufresne</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/roxane.webp" alt="">
            <p class="text name"><span>/</span> Roxane Noiseux</p>
          </div>

          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event8' }">
          <div class="top">
            <p class="hour title">13h15</p>
            <h3 class="title small">Think - Do - Say</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/ron.webp" alt="">
            <p class="text name"><span>/</span> Ron Tite</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event9' }">
          <div class="top">
            <p class="hour title">14h</p>
            <h3 class="title small">Pinterest : Full Funnel marketing</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Tunnel de conversion marketing complet sur Pinterest;</li>
              <li class="text">Comment combiner les stratégies organiques et payantes.</li>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/pinterest.webp" alt="">
            <p class="text name"><span>/</span> Pinterest</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event10' }">
          <div class="top">
            <p class="hour title">14h45</p>
            <h3 class="title small">Leadership et marketing : 2 métiers qui se rencontrent</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
            </ul>
          </div>
          <div class="speaker">
            <img src="@/assets/img/catherine.webp" alt="">
            <p class="text name"><span>/</span> Catherine Patry </p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event11' }">
          <div class="top">
            <p class="hour title">15h30</p>
            <h3 class="title small">Connexion formule réseautage</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Autour d'une bière : présentation des techniques de brassage éprouvées et dégustation</li>
            </ul>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>
      </div>
      <div class="overlay" @click.prevent="closeconference"></div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'


import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import createContact from '@/misc/firebase-init'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Le LAB SUBSTANCE - Votre rendez-vous périodique',
        separator: ' ',
        complement: '',
      },
      meta: [
        {
          name: 'description',
          content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        { name: 'twitter:title', content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        { property: 'og:site_name', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        {
          property: 'og:description',
          content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://substance-10-ans.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://substance-10-ans.netlify.app/img/og.jpg'
        }
      ]
    }
  },

  mounted() {
    this.initGsapUtils()
  },

  watch: {
    $route: {
      handler($route) {
        if ($route && $route.query.success === '1') {
          this.openform()
          this.isSuccess = true
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      form: false,
      conference: false,
      show: null,
      isSuccess: false,
      formData: {
        nom: '',
        entreprise: '',
        courriel: '',
        formations: []
      },
      isSending: false
    }
  },

  methods: {

    initGsapUtils() {
      const mtl = document.querySelector('.anim-mtl')
      const date = document.querySelector('.anim-date')
      const hour = document.querySelector('.anim-hour')

      if (mtl && date && hour) {

        gsap.to(mtl, {
          scrollTrigger: {
            trigger: mtl,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          rotation: 180,
          ease: 'none'
        })

        gsap.to(date, {
          scrollTrigger: {
            trigger: date,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          x: '-50%',
          ease: 'none'
        })

        gsap.to(hour, {
          scrollTrigger: {
            trigger: hour,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          x: '50%',
          ease: 'none'
        })
      }
    },
    
    openconference(uid) {
      this.conference = true
      this.show = uid
    },
    closeconference() {
      this.conference = false
      this.show = null
    },
    openform() {
      this.form = true
    },
    closeform() {
      this.form = false
    },
    addFormation(formation, uniqueTo = false) {
      if (uniqueTo) {
        this.formData.formations = this.formData.formations.filter(item => !item.includes(uniqueTo))
        this.formData.formations.push(formation)
        return
      }

      if (this.formData.formations.includes(formation)) {
        this.formData.formations = this.formData.formations.filter(item => item !== formation)
      } else {
        this.formData.formations.push(formation)
      }
    },
    async sendForm(event) {
      event.preventDefault()

      if (this.isSending) {
        return
      }

      this.isSending = true
      const contact = this.formData

      try {
        await createContact(contact)
        this.formData = {
          nom: '',
          entreprise: '',
          courriel: '',
          formations: []
        }
        window.location.href = '/?success=1'
      } catch (e) {
        this.error = e
        this.isSending = false
      }
    }
  },

  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
